@font-face {
  font-family: 'DM Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Regular'), url('assets/fonts/DMSans-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Italic'), url('assets/fonts/DMSans-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Medium'), url('assets/fonts/DMSans-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Medium Italic'), url('assets/fonts/DMSans-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Bold'), url('assets/fonts/DMSans-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'DM Sans Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('DM Sans Bold Italic'), url('assets/fonts/DMSans-BoldItalic.woff') format('woff');
  }
body {
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-family: 'DM Sans Regular' !important;
     font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #eeeeee;
    font-feature-settings: 'tnum', "tnum";

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  height: 72px !important;
  width: calc(100% - 8px) !important;
  width: -webkit-calc(100% - 8px) !important;
  margin: 4px;
}

.ant-layout {
  background: #FBFAFF !important;
}

.ant-layout.ant-layout-has-sider {
  min-height: unset;
  height: 100%;
}

.ant-layout-header {
  padding-left: 1px !important;
  padding-top: 0px !important;
  color: rgba(0, 0, 0, 0.85);
  height: 80px !important;
  background: #fff !important;
} 

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-content {
  background: #FBFAFF ;
  border-radius: 10px;
  border-radius: 10px ;
  min-height: 280px;
  margin: 0 !important;
  padding: 15px 15px 15px 15px !important;
  overflow-y: auto;
  height: 100%;
}

.ant-layout-sider {
  background: var(--amplify-background-dark) !important;
  width: 60px !important;
  flex: 0 0 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}

.ant-layout-sider .ant-menu-inline {
  border-right: none;
  background: transparent;
  margin-top: 15px;
}

.ant-menu-inline > .ant-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  color: #fff;
  padding: 5px 5px !important;
  width: 40px !important;
  border-radius: 8px;
  text-align: center;
  margin: 0 10px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--amplify-primary-color) !important;
}
.user-left-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item{
  color:var(--amplify-text-light);
}
.user-left-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
  color: var(--amplify-primary-color);
}
.user-left-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover{
  color: var(--amplify-primary-color);
}
.user-left-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover img,
.user-left-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected img{
  filter:invert(74%) sepia(278%) saturate(5342%) hue-rotate(248deg) brightness(81%) contrast(111%);
}

.user-left-menu li{
  font-family:"DM Sans Bold";
  font-size: 14px;
}
.ant-menu-inline .ant-menu-item::after {
  border: none !important;
}

.lm-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.lm-icon.activity {
  background-image: url(../src/assets/img/svg/left-menu/activity.svg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .lm-icon.activity {
  background-image: url(../src/assets/img/svg/left-menu/activity-white.svg);
}

.lm-icon.interactions {
  background-image: url(../src/assets/img/svg/left-menu/interactions.svg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .lm-icon.interactions {
  background-image: url(../src/assets/img/svg/left-menu/interactions-white.svg);
}

.lm-icon.recent {
  background-image: url(../src/assets/img/svg/left-menu/recent.svg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .lm-icon.recent {
  background-image: url(../src/assets/img/svg/left-menu/recent-white.svg);
}

.lm-icon.customers {
  background-image: url(../src/assets/img/svg/left-menu/customers.svg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .lm-icon.customers {
  background-image: url(../src/assets/img/svg/left-menu/customers-white.svg);
}

.lm-icon.voicemail {
  background-image: url(../src/assets/img/svg/left-menu/voicemail.svg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .lm-icon.voicemail {
  background-image: url(../src/assets/img/svg/left-menu/voicemail-white.svg);
}

.lm-icon.keypad {
  background-image: url(../src/assets/img/svg/left-menu/keypad.svg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .lm-icon.keypad {
  background-image: url(../src/assets/img/svg/left-menu/keypad-white.svg);
}

.lm-icon.settings {
  background-image: url(../src/assets/img/svg/left-menu/settings.svg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .lm-icon.settings {
  background-image: url(../src/assets/img/svg/left-menu/settings-white.svg);
}

.ant-layout-sider-trigger {  
  background: #ece0e0  !important;
}

.ant-layout-sider-zero-width-trigger { 
  background: #72b1ecb3 !important;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 87px !important;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}


.amplify-sign-out {
  height: 30px !important;
  min-width: 15px !important;
  /* width: 100px; */
  border-radius: 10px !important;
  font-size: 10px !important;
  margin: 6px !important;
  padding: 5px !important;
}


:root {
  --amplify-primary-color: #573BFF;
  --amplify-primary-tint: #4735e3;
  --amplify-primary-shade: #7060ff;
  --amplify-background-dark: #030014;
  --amplify-background-color: #fbfaff;
  --amplify-text-dark: #030014;
  --amplify-text-secondary: #573BFF;
  --amplify-text-light: #9a99a1;
  --amplify-text-seclight: #030014;
  --amplify-error: #FE4951; 
  --amplify-error-tint: #f7d4dc;
  --amplify-success : #2FC260;
  --amplify-light: #dfdfe6;
  --amplify-light-tint: #ceced8;
  --amplify-white: #ffffff;
  --amplify-gray :#999999;
  
}

[data-amplify-theme] {
  height: 100% !important;
}

.ant-btn-primary {
  color: #fff;
  border-color: var(--amplify-primary-color) !important;
  background: var(--amplify-primary-color) !important;
}

.ant-btn-primary:hover {
  background: var(--amplify-primary-shade) !important;
}

.dd-content-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}



.login-main {
  height: 100%;
  width: 100%;
}

.login-main .login-left {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  /* padding: 0 10%; */
  width: 50%;
}
.login-main .login-left .login-left-content {
  padding: 0 10%;
}
.login-main .login-left .login-logo {
  background-image: url(../src/assets/img/svg/logo-main.svg);
  background-position: center;
  margin-bottom: 15px;
  width: 130px;
  height: 32px;
}

.login-main .login-left h1{
  font-size: 46px; 
  color: #573BFF; 
  line-height: 40px;
  font-family: 'DM Sans Bold';
}

.login-main .login-left p {
  font-size: 16px;
   color: rgba(3, 0, 20, 0.6); 
   margin-bottom: 5px;
   padding: 0;
   line-height: 1;
}

.login-main .login-right {
  background-color: #FBFAFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.amplify-tabs-item:active {
  color: #5846fb !important;
}

.amplify-tabs-item[data-state=active] {
  color: #5846fb !important;
  border-color: #5846fb !important;
  font-size: 18px;
  font-family: 'DM Sans Bold';
}

.amplify-input {
  color: var(--amplify-components-fieldcontrol-color);
  font-size: 14px !important;
  background-color: #573BFF0D !important;
  border-color: #452ECE33 !important;
  outline: none !important;
}
.amplify-select__wrapper select{
  color: var(--amplify-components-fieldcontrol-color);
  font-size: 14px !important;
  background-color: #573BFF0D !important;
  border-color: #452ECE33 !important;
  outline: none !important;
}
.amplify-checkbox__icon {
  background-color: #4735e3 !important
}

.amplify-tabs-item {
  border-color: #eeebff !important;
  font-size: 18px;
  font-family: 'DM Sans Bold';
}

.amplify-label {
  color: #030014 !important;
  font-size: 14px !important;
}

.amplify-text {
  color: #030014 !important;
  font-size: 14px !important;
}

.amplify-field__error-message {
  color: #FE4951 !important;
  font-size: 14px;
}

.amplify-button {
  border-color: #452ECE33 !important;
}

.amplify-button:hover {
  background-color: #eeebff !important;
  border-color: #4735e3 !important;
  color: #4735e3 !important;
}

.amplify-button--primary {
  background-color: #5846fb !important; 
  font-family: 'DM Sans Bold';
  font-size: 14px !important;
  font-weight: normal !important;

}

.amplify-button--primary:hover {
  color: #ffffff !important;
  background-color: #4735e3 !important; 
}

.amplify-tabs-item {
  color: #030014 !important;
}

.amplify-tabs-item:hover {
  color: #5846fb !important;
}

.amplify-radio__input:checked + .amplify-radio__button {
  color: #5846fb !important;
}

.amplify-radio__button {
  border-color: #573BFF66 !important;
}

.amplify-button--link {
  color: #5846fb !important;
  box-shadow: none !important;
  font-size: 16px !important;
    font-family: 'DM Sans Regular' !important;
}

.account-link button{
  color: #573BFF;
  padding: 0;
}

.flex-row-name .amplify-textfield{
  width: 100%;
}
.federated-sign-in-container button{
  background: #fff;
}

/* Break Types */
.user-name{
  font-family: "DM Sans Bold";
  font-size: 20px;
  color: #030014;
}
.break-type{
  font-family: "DM Sans Bold";
  font-size: 14px;
  color: rgba(3, 0, 20, 0.6);
}
.break-timer{
  font-family: "DM Sans Bold";
  font-size: 32px;
  margin-top: 35px;
}
.avatar-block{
  margin-bottom: 15px;
}
.avatar-block .ant-avatar-image{
  width: 120px !important;
  height: 120px !important;
}
.label-unlock{
  color: rgba(3, 0, 20, 1);
  font-family: "DM Sans Regular";
  font-size: 14px;
  text-align: center;
  display: block;
  margin-top: 32px;
  margin-bottom: 5px;
}
.ant-input-password input{
  color: var(--amplify-components-fieldcontrol-color);
  font-size: 14px !important;
  background-color: transparent!important;
  outline: none !important;
  padding: 5px !important;
    border-radius: 5px !important;
}

.ant-input-password{
  color: var(--amplify-components-fieldcontrol-color);
  font-size: 14px !important;
  background-color: #573BFF0D !important;
  border-color: #452ECE33 !important;
  outline: none !important;
}

.max-width{
width: 208px;
margin: 0 auto;
} 
.btn-block{
margin-top: 16px;
}
.btn-block button{
  color: #fff;
  width: 100%;
  padding: 10px;
    height: inherit;
}
.btn-block button span{
  color:#fff;

}



/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #583ffc53; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #583ffc31; 
}